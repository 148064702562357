<template>
  <input v-model="modelValue" ref="inputRef" class="rs-input" :class="{ 'rs-input--error': hasError }">
</template>

<script setup lang="ts">
import { useVModel } from '@vueuse/core'

const props = defineProps({
  modelValue: String,
  hasError: Boolean,
})

const emit = defineEmits(['update:modelValue'])

const modelValue = useVModel(props, 'modelValue', emit)

const inputRef = ref(null)
</script>

<style scoped>
.rs-input {
  @apply h-12 m-0 text-base w-full outline-none overflow-hidden bg-transparent border border-gray-200 rounded px-4;
  font-family: 'Encode Sans', -apple-system, sans-serif;
}
.rs-input:disabled {
  @apply cursor-not-allowed bg-gray-100;
}
.rs-input--error {
  @apply border-red-500;
}
</style>
